<template>
  <div>
    <!-- 申报教程子页面 -->
    <div class="topUsers" style="margin-top: 20px;">
      <div>
        <el-input
          clearable
          v-model="input"
          placeholder="请输入企业名称关键字"
          @keyup.enter.native="handleSearch()"
        ></el-input>
        <el-button class="search" @click="handleSearch()">查询</el-button>
      </div>
      <el-button class="search" @click="add()">新增</el-button>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="正在加载中···"
      :data="tableData.data"
      style="width: 100%"
    >
      <el-table-column prop="cover_url" label="封面">
        <template slot-scope="scope">
          <el-popover placement="top-start" title trigger="hover">
            <img :src="gqbpath + scope.row.cover_url" alt style="height: 180px;" />
            <img slot="reference" :src="gqbpath + scope.row.cover_url" style="height: 90px;" />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="source_url" label="视频来源" show-overflow-tooltip></el-table-column>
      <el-table-column prop="create_time" label="发布时间" width="150px"></el-table-column>
      <el-table-column prop="address" label="操作" width="150px">
        <template slot-scope="scope">
          <el-button class="edit" size="small" @click="goEdit(scope.row.id)">编辑</el-button>
          <el-button class="del" size="small" @click="goDel(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-sizes="[2, 10, 20, 40]"
        :page-size="10"
        layout="sizes, prev, pager, next,total,jumper"
        :total="tableData.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import Gqburl from '../../../components/global.vue'
export default {
  data () {
    return {
      loading: true,
      gqbpath: Gqburl.userSite,
      input: '',
      tableData: {},
      videoParams: {
        type: 'video',
        page: 1,
        limit: 10,
        keywords: ''
      }
    }
  },
  activated () {
    this.videoParams
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.axios.get('/api/website/article_list', { params: { ...this.videoParams } }).then((res) => {
        // console.log(res, '申报教程数据')
        this.loading = false
        this.tableData = res.data
      })
    },
    handleCurrentChange (val) {
      this.getList(this.videoParams.page = val)
    },
    handleSizeChange (val) {
      this.getList(this.videoParams.limit = val)
    },
    handleSearch () {
      this.getList(this.videoParams.keywords = this.input)
    },
    add () {
      this.$router.push({ path: '/website/school/course_add' })
    },
    goEdit (id) {
      this.$router.push({ path: '/website/school/course_add', query: { id: id, type: 'video' } })
    },
    goDel (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete('/api/website/delete_article', {
          params: {
            type: 'video',
            id: id
          }
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
  },
}
</script>

<style scoped>
.flex {
  display: flex;
}
</style>