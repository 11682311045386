<template>
  <div class="cloud">
    <Header title="云学院" index="首页" titleOne="官网管理" beforeTitle="云学院" />
    <div class="content">
      <el-tabs v-model="activeName" type="card" @tab-click="tabClick">
        <el-tab-pane label="申报教程" name="first">
          <Course ref="video" />
        </el-tab-pane>
        <el-tab-pane label="图文解读" name="second">
          <Imgtext ref="img" />
        </el-tab-pane>
        <el-tab-pane label="模板下载" name="third">
          <Tem ref="tem" />
        </el-tab-pane>
        <el-tab-pane label="平台活动" name="found">
          <Play ref="activity" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Course from '../../components/website/cloudSchool/courseTabs.vue'
import Imgtext from '../../components/website/cloudSchool/imgtextTabs.vue'
import Tem from '../../components/website/cloudSchool/temTabs.vue'
import Play from '../../components/website/cloudSchool/palyTabs.vue'
export default {
  components: {
    Course,
    Imgtext,
    Tem,
    Play,
  },
  data () {
    return {
      activeName: 'first'
    }
  },
  methods: {
    tabClick () {
      if (this.activeName == 'first') {
        this.$refs.video.getList()
      } else if (this.activeName == 'second') {
        this.$refs.img.getList()
      } else if (this.activeName == 'third') {
        this.$refs.tem.getList()
      } else if (this.activeName == 'found') {
        this.$refs.activity.getList()
      }
    },
  }
}
</script>

<style>
</style>